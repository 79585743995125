<template>
  <div class="side-bar-card mt-1">
    <div class="info-card-label">
      <label class="mr-auto font-bold">Methods</label>
      <font-awesome-icon
        icon="info"
        class="mr-3 cursor-pointer"
        title="Component Methods Definition."
      />
    </div>
    <div
      v-for="(method, index) of methodsList"
      :key="index"
    >
      <div class="flex my-1">
        <label class="w-1/4"> name </label>
        <div class="w-3/4">
          <input :value="method.name" @input="updateMethodName(index, $event.target.value)" type="text" class="w-full" />
        </div>
      </div>
      <div class="flex flex-col mb-1">
        <div class="flex justify-between">
          <label> Method Body </label>
          <font-awesome-icon
            icon="info"
            class="mr-3 cursor-pointer"
            title="Defin as function (params...) { body }"
          />
        </div>
        <ZoomView>
          <TemplateEditor
            :template="method.body"
            @set="code => updateMethodBody(index, code)"
          />
        </ZoomView>
      </div>
      <button
        class="hover:text-red-500 ml-auto text-xs"
        @click="deleteMethod(index)"
      >Delete This Method</button>
    </div>
    <div class="flex mt-3 w-full side-bar-input">
      <input type="text" class="min-w-0 flex-1 new-method-input" placeholder="New Method" />
      <button
        class="ml-2 cursor-pointer"
        @click="add"
      >
        Add
      </button>
    </div>
  </div>
</template>
<script>
import TemplateEditor from './TemplateEditor.vue';
import ZoomView from '../ui/ZoomView.vue';

export default {
  name: "MethodEdit",
  components: {
    TemplateEditor,
    ZoomView
  },
  props: {
    methodsList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    add (){
      const name = document.querySelector(`.new-method-input`).value
      this.$emit('add', { name })
    },
    updateMethodName (index, name) {
      this.$emit('update', { key: 'name', index, value: name })
    },
    updateMethodBody (index, body) {
      this.$emit('update', { key: 'body', index, value: body })
    },
    deleteMethod (index) {
      this.$emit('remove', { index })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
