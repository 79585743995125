<template>
  <vue-final-modal
    classes="flex justify-center items-center"
    v-model="isShowModal"
  >
    <div
      class="bg-white px-6 py-4 w-full h-full overflow-auto flex flex-col align-items-center rounded"
    >
      <div class="text-lg mb-5">Import Site</div>
      <div
        @dragover.prevent="dragStarted"
        @dragleave.prevent="dragLeave"
        @drop.prevent="drop"
        class="drag-image flex flex-col justify-content-center align-items-center rounded"
        :class="isShowDragImage ? 'drag-over' : ''"
      >
        <div v-if="!selectedFile" class="flex flex-col justify-content-center align-items-center">
          <div v-if="!isShowDragImage" class="text-base">
            Drag & Drop File Here
          </div>
          <div v-else class="text-base">Release to Select File</div>
          <div class="text-sm">OR</div>
          <button
            @click="openFileUploadPopup"
            class="btn btn-default btn-bla ck"
          >
            Browse File
          </button>
        </div>
        <div v-else class="flex flex-col justify-content-center align-items-center">
          <i class="fa fa-file text-8xl text-gray-400" aria-hidden="true"></i>
          <div>{{ this.selectedFile.name }}</div>
        </div>
        <input
          type="file"
          id="import-file-input"
          @change="uploadedThroughButton"
          hidden
          accept=".json"
        />
      </div>
      <small class="mt-1 text-gray-400"
        >Please upload a valid JSON file. Only files with a .json extension are
        accepted.</small
      >
      <a
        @click="uploadFileToServer"
        href="#"
        class="btn btn-default btn-bla ck mt-5"
      >
        <div
          v-if="loading"
          class="spinner-border"
          style="width: 1rem; height: 1rem"
          role="status"
        ></div>
        <div v-else>Import</div>
      </a>
    </div>
  </vue-final-modal>
</template>
<script>
import * as siteService from "../service/site";

export default {
  name: "SingleImageDrag",
  data: () => ({
    isShowModal: true,
    isShowDragImage: false,
    selectedFile: null,
    loading: false,
    isFetchData: false,
  }),

  watch: {
    isShowModal() {
      this.$emit("onCloseImportModal", this.isFetchData);
    },
  },
  methods: {
    dragStarted() {
      console.log("drag entered");
      this.isShowDragImage = true;
    },
    dragLeave() {
      this.isShowDragImage = false;
    },
    drop(event) {
      const dropArea = document.querySelector(".drag-image");
      let file = event.dataTransfer.files[0];
      this.selectedFile = file;
      dropArea.classList.add("active");
      this.viewFile(file);
    },
    uploadedThroughButton(event) {
      this.selectedFile = event.target.files[0];
      console.log("input", this.selectedFile, event);
      this.viewFile(this.selectedFile);
    },
    viewFile(file) {
      const dropArea = document.querySelector(".drag-image"),
        dragText = dropArea.querySelector("h6");
      let fileType = file.type;
      let validExtensions = ["application/json"];
      if (!validExtensions.includes(fileType)) {
        alert("This is not an JSON File!");
        dropArea.classList.remove("active");
        dragText.textContent = "Drag & Drop to Upload File";
      }
    },
    openFileUploadPopup() {
      document.querySelector("#import-file-input").click();
    },
    async uploadFileToServer() {
      if (!this.selectedFile) {
        return;
      }
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      this.loading = true;
      try {
        await siteService.importSite(formData);
        this.isFetchData = true;
        this.isShowModal = false;
        this.$notify("Site Imported Successfully.");
      } catch (err) {
        console.log("err: while importing", err);
        this.$notify({
          type: "warn",
          text: err,
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style>
.drag-image {
  border: 1px dashed grey;
  height: 300px;
  width: 350px;
  font-weight: 400;
}
.drag-image.active {
  border: 2px solid grey;
}

.drag-image.drag-over {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border: 3px dashed #00aeef;
}
</style>
