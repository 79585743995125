import { api_host } from '../constants'

export const getSites = async (archived = false) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/list?archived=${archived}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const getSiteByUri = async (uri) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/by-uri/${uri}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const createSite = async (data) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const updateSite = async (id, data) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export const deleteSite = async (id) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const updateSiteVersion = async (uri, oldVersion, newVersion) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/update-site-version`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      uri, oldVersion, newVersion
    })
  }).then(res => res.json())
}

export const getSiteVersions = async (uri) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/versions/${uri}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const getSiteWithVersion = async (uri, version) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/by-uri-version/${uri}/${version}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const setSiteVersion = async (uri, body) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/set-site-version/${uri}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      published_version: body.published_version,
      current_version: body.current_version,
    })
  }).then(res => res.json())
}

export const deleteSiteVersion = async (uri, version) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/delete-site-version/${uri}/${version}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const archiveSite = async (uri) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/archive/${uri}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const unArchiveSite = async (uri) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/unarchive/${uri}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const uploadFile = async (file, key = '') => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('key', key)
  const token = window.localStorage.getItem('token')

  return await fetch(`${api_host}/upload-file`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    body: formData
  }).then(res => res.json())
}

export const getFileLink = async (key) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/s3/${key}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}

export const duplicateSite = async (source, dest) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/duplicate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      source ,dest
    })
  }).then(res => res.json())
}

export const exportSite = async (siteId) => {
  const token = window.localStorage.getItem('token')
  return await fetch(`${api_host}/sites/export-site?uri=${siteId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => res.json())
}


export const importSite = async (formData) => {
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem('token')
    fetch(`${api_host}/sites/import-site`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    }).then(res => res.json())
      .then(res => {
        if (res.success) {
          resolve(res)
        } else {
          reject(res)
        }
      })
      .catch(err => reject(err))
  })
}